<style lang="scss">
#view-proposal-invoice {
  min-height: 100%;
  @apply bg-grey-light;

  .top-bar {
    @apply rounded;
    @apply mb-8;
    border: 1px solid #D1D1D1;

    div {
      @apply px-4;
      @apply py-2;
      border: none;
    }

    .border {
      border-left: 1px solid #D1D1D1;
    }

    .active {
      @apply bg-blue;
      color: #fff;
    }
  }

  .heading {
    background: #F9F9F9;
    color: #000;
    line-height: 58px;
    border-bottom: 1px solid;
    border-color: #ededed;
    margin-bottom: 10px;
    font-weight: normal;
  }

  .pdf-loader {
    border-top: 5px solid #0942BC;
    width: 0%;
    top: 0;
    left: 0;
    position: fixed;
  }
}

#card-element {
  padding: 10px;
}

.StripeElement {
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.paymentMethod {
  span {
    cursor: pointer;

    &:hover {
      text-decoration: underline
    }

    &.active {
      font-weight: bold;
    }
  }
}

.left-celebrate {
  position: absolute;
  left: 30px;
  top: -35px;

  @media (max-width: 640px) {
    left: 0px;
    top: -45px;
    transform: scaleY(-1) rotate(-90deg);
  }

}

.right-celebrate {
  position: absolute;
  right: 20px;
  top: -30px;

  @media (max-width: 640px) {
    top: 0;
    right: 10px;
    bottom: -40px;
    transform: rotate(180deg);
  }
}
</style>
<template>
  <div
    id="view-proposal-invoice"
    class="bg-grey-light pt-12"
  >
    <vue-headful
      :title="`${metaTitle} | Octoa`"
    />

    <modal
      name="paymentModal"
      class="modal overflow-visible"
      width="90%"
      height="280"
      :max-width="600"
      :adaptive="true"
    >
      <div class="flex justify-between">
        <div class="title">
          Add payment
        </div>
        <div
          class="close "
          @click="$modal.hide('paymentModal')"
        />
      </div>

      <div class="flex mt-5 items-center">
        <div class="w-4/12 md:w-32">
          <div class="pr-2">
            Payment date:
          </div>
        </div>
        <div class="w-8/12 md:w-4/12">
          <v-date-picker
            v-model="addPayment.date"
            class="datepicker"
            name="payment_date"
            :popover="{ visibility: 'click' }"
            :input-props="{
              placeholder: &quot;Date of payment&quot;,
            }"
            :masks="{L: 'WWW, MMM D, YYYY'}"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                type="text"
                :value="inputValue"
                v-on="inputEvents"
              >
            </template>
          </v-date-picker>
        </div>
      </div>

      <div class="flex flex-wrap mt-3 items-center">
        <div class="w-4/12 md:w-32">
          <div class="pr-2">
            Amount ({{ currency.sign }})
          </div>
        </div>
        <div class="w-8/12 md:w-4/12">
          <vue-numeric
            v-model="addPayment.amount"
            :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
            :precision="2"
            :currency="currency.sign"
            :minus="false"
            :decimal-separator="currency.decimal_separator"
            :thousand-separator="currency.thousand_separator"
            :min="0"
            :max="remainingDueTotal"
          />
        </div>
        <div class="w-full md:w-auto mt-5 md:mt-0 md:ml-4">
          Remaining amount:
          <vue-numeric
            :value="remainingDueTotal"
            :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
            :precision="2"
            :currency="currency.sign"
            read-only
            :minus="false"
            :decimal-separator="currency.decimal_separator"
            :thousand-separator="currency.thousand_separator"
            :min="0"
          />
        </div>
      </div>

      <div class="flex justify-center md:justify-end flex-wrap mt-8 items-center">
        <div class="w-1/2 text-center md:text-right md:w-3/12">
          <v-button
            class="green-btn"
            @click="recordPayment"
          >
            Add payment
          </v-button>
        </div>
        <div class="w-1/2 text-center md:text-right md:w-1/12 md:ml-5">
          <div
            class="underline text-green cursor-pointer"
            @click="$modal.hide('paymentModal')"
          >
            Cancel
          </div>
        </div>
      </div>
    </modal>

    <v-inner-header
      v-if="viewType == 'edit'"
      type="invoice"
      mode="view"
      :item="invoice"
      :redirect="invoice.project_id ? `/projects/${invoice.project_id}` : `/invoices`"
      :template="false"
      @addPayment="showAddPayment"
      @edit="edit"
      @share="copyShareLink"
      @deleteItem="deleteItem"
      @markAsSent="markAsSent"
      @markAsUnsent="markAsUnsent"
    />

    <div
      v-if="isPdfLoading"
      class="pdf-loader"
      :style="{ width: pdfLoaderProgress + '%' }"
    />

    <div
      v-if="!loading && !error && !paymentSucceed"
      class="print-hidden max-w-xl mx-4 lg:mx-auto text-right"
      :class="{ 'pt-10' : viewType == 'edit'}"
    >
      <div
        v-if="!isPdfLoading && invoice.invoice_number"
        class="flex items-center cursor-pointer justify-end text-green mb-5"
      >
        <div>
          <img
            src="@/assets/img/icons/ico-save-green.svg"
            width="18"
            class="mr-2"
          >
        </div>
        <div
          class="underline mb-1"
          @click="savePdf"
        >
          <span class="hidden md:inline">{{ $translate('saveAsPdf', locale) }}</span>
          <span class="inline md:hidden">{{ $translate('openAsPdf', locale) }}</span>
        </div>
      </div>
      <div
        v-if="isPdfLoading"
        class="text-right mb-2 mr-1"
      >
        <img
          src="@/assets/img/icons/loader.svg"
          width="35"
        >
      </div>
    </div>

    <div
      v-if="!error && !loading && remainingDueTotal <= 0 && !paymentSucceed"
      class="text-center py-2 text-white font-medium rounded-b-none rounded bg-green max-w-xl mx-4 lg:mx-auto"
    >
      {{ $translate('invoicePaid', locale) }}
    </div>
    <div class="z-0 mb-32">
      <div
        v-if="error"
        class="text-center h-screen -mt-12 flex items-center flex-wrap max-w-xl mx-auto"
      >
        <div class="w-full -mt-10">
          <router-link to="/">
            <img
              src="@/assets/img/octoa.png"
              width="60"
              class="pb-10"
            >
          </router-link>
          <br><br>
          <div class="-mx-4 flex text-left items-center">
            <div class="w-1/2 px-4">
              <h1 class="text-5xl">
                Sorry, this invoice isn’t here.
              </h1>
              <h2 class="text-3xl">
                You didn’t do anything wrong.
              </h2>
            </div>
            <div class="w-1/2 text-base px-4">
              <div class="font-bold text-lg">
                Did you follow a link from someone?
              </div>
              <div class="mt-2">
                If you received this link from someone, this invoice may already have been removed or the link has
                changed.
              </div>

              <div class="mt-5 font-bold text-lg">
                Did you follow a link from here?
              </div>
              <div class="mt-2">
                If you reached this page from another part of octoa.com, please let us know so we can correct our
                mistake.
              </div>

              <div class="mt-5 font-bold text-lg">
                Did you type the URL?
              </div>
              <div class="mt-2">
                You may have typed the address (URL) incorrectly. Check to make sure you’ve got the exact right
                spelling, capitalization, etc.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="mb-10 pb-10 pt-8 proposal-form bg-white shadow rounded max-w-xl mx-4 lg:mx-auto"
        :class="{ 'rounded-t-none' : remainingDueTotal <= 0}"
      >
        <div
          v-if="loading"
          class="w-full px-2 text-center"
        >
          <img
            src="@/assets/img/icons/loader.svg"
            width="50"
            class="my-32 text-center"
          >
        </div>
        <div
          v-if="!loading && paymentSucceed"
          class="text-center"
        >
          <div class="relative px-5 md:px-24 my-20 ">
            <div v-if="paymentSucceed != 'failed'">
              <span class="left-celebrate">
                <img
                  src="@/assets/img/icons/ico-left-celebrate.svg"
                  width="57"
                >
              </span>
              <div class="font-bold leading-snug text-2xl md:text-3xl lg:text-4xl">
                {{ $translate('invoiceNumber', locale) }} {{ numberPrefix }}{{ invoiceNumber }}
                <div class="mt-2">
                  {{ $translate('thankYou', locale) }}
                </div>
              </div>
              <span class="right-celebrate"><img
                src="@/assets/img/icons/ico-right-celebrate.svg"
                width="57"
              ></span>
            </div>
            <div v-else>
              <img
                src="@/assets/img/icons/ico-warning-diamond.svg"
                width="30"
                class="mb-5"
              >
              <div class="font-bold leading-snug text-2xl md:text-3xl lg:text-4xl">
                {{ $translate('oops', locale) }}
              </div>
            </div>
            <a
              class="green-btn mt-20 md:mt-12 inline-block"
              @click="reload"
            >
              <span class="md:px-5">{{ $translate('bringMeBackInvoice', locale) }}</span>
            </a>
            <div v-if="paymentSucceed == 'failed'">
              <p class="mt-5">
                {{ $translate('errorPersist', locale) }} <a href="mailto:hello@octoa.com">hello@octoa.com</a>.
              </p>
            </div>
          </div>
        </div>
        <div
          v-if="!loading && !paymentSucceed"
          class="proposal flex flex-wrap max-w-xl mx-auto items-center"
        >
          <div class="w-full mx-auto">
            <div class="flex flex-wrap px-8">
              <div class="w-full md:w-1/2 print-50">
                <div
                  v-if="logo.path"
                  class="logo"
                >
                  <img
                    :src="logo.path"
                    width="100%"
                    :style="[logo.width > logo.height ? {'max-width' : '278px'} : '', logo.width < logo.height ? { 'width' : 'auto', 'max-height':'250px'} : '', logo.width == logo.height ? { 'width' : '88px', 'height':'88px'} : '' ]"
                  >
                </div>
                <div class="flex flex-wrap items-center">
                  <div class="w-full md:w-2/5 lg:w-4/12  text-grey-semi-light">
                    {{ $translate('invoiceNumber', locale) }}
                  </div>
                  <div class="mt-3 md:mt-0 w-full md:w-3/5 lg:w-8/12 ">
                    <div
                      v-if="invoiceNumber != ''"
                      class="font-bold"
                    >
                      {{ numberPrefix }}{{ invoiceNumber }}
                    </div>
                    <div
                      v-else
                      class="pt-1 mt-px"
                    >
                      {{ $translate('generatedUpon', locale) }} <span
                      class="mr-2 md:mr-0 inline mt-1 md:mt-0">{{ $translate('sending', locale) }}<v-help-tooltip
                      :options="invoiceNumberToolTipContent"
                    /></span>
                    </div>
                  </div>
                </div>

                <div class="my-5 flex flex-wrap items-center">
                  <div class="w-full md:w-2/5 lg:w-4/12  text-grey-semi-light">
                    {{ $translate('issued', locale) }}
                  </div>
                  <div class="mt-3 md:mt-0 w-full md:w-3/5 lg:w-8/12 ">
                    {{ issuedAt | viewDate(locale) }}
                  </div>
                </div>

                <div class="flex flex-wrap items-center ">
                  <div class="w-full md:w-2/5 lg:w-4/12  text-grey-semi-light">
                    {{ $translate('dueDateInvoice', locale) }}
                  </div>
                  <div class="mt-3 md:mt-0 w-full md:w-3/5 lg:w-8/12 ">
                    {{ validUntil | viewDate(locale) }}
                  </div>
                </div>

                <div
                  v-show="subject != null"
                  class="flex flex-wrap items-center mt-5"
                >
                  <div class="w-full md:w-2/5 lg:w-4/12  text-grey-semi-light">
                    {{ $translate('subject', locale) }}
                  </div>
                  <div class="mt-3 md:mt-0 w-full md:w-3/5 lg:w-8/12 ">
                    {{ subject }}
                  </div>
                </div>
              </div>
              <div class="w-full md:w-1/2 md:text-right text-sm print-50 mt-5 md:mt-0">
                <div class="flex">
                  <div class="w-1/3 md:w-1/2 md:text-right">
                    <div class="mr-12 text-grey-semi-light leading-loosen">
                      {{ $translate('from', locale) }}
                    </div>
                  </div>
                  <div
                    v-if="user"
                    class="w-2/3 md:w-1/2 text-left text-grey-darker leading-loosen break-words"
                  >
                    <strong class="text-black">{{ company.name }}</strong><br>
                    <pre style="font-family:inherit">{{ company.address }}</pre>
                    <div v-if="locale == &quot;fi&quot; || locale == &quot;de&quot;">
                      <span v-if="company.postal_code">{{ company.postal_code }} </span>
                      <span
                        v-if="company.city"
                        style="font-family:inherit"
                      >{{ company.city }}</span>
                    </div>
                    <div v-else>
                      <pre
                        v-if="company.city"
                        style="font-family:inherit"
                      >{{ company.city }}<span v-if="company.postal_code">,</span> {{ company.postal_code }}</pre>
                    </div>
                    {{ company.country }}
                  </div>
                </div>
                <div
                  v-if="paymentInfo"
                  class="flex flex-wrap"
                >
                  <div class="w-1/3 md:w-1/2 md:text-right"/>
                  <div class="w-2/3 md:w-1/2 text-left text-grey-darker leading-loosen break-words">
                    <pre>{{ paymentInfo }}</pre>
                  </div>
                </div>
                <div class="flex flex-wrap mt-5">
                  <div class="w-1/3 md:w-1/2 md:text-right">
                    <div class="mr-12 text-grey-semi-light leading-loosen break-words">
                      {{ $translate('to', locale) }}
                    </div>
                  </div>
                  <div class="w-2/3 md:w-1/2 text-left leading-loosen break-words">
                    <div
                      v-if="contact"
                    >
                      <strong v-if="contact.company_name"> {{ contact.company_name }}<br></strong>
                      <div v-if="contact.same_as_billing_address">
                        {{ contact.full_name }}<br>
                        <pre style="font-family:inherit">{{ contact.address }}</pre>
                        <div v-if="locale == &quot;fi&quot; || locale == &quot;de&quot;">
                          <span v-if="contact.postal_code">{{ contact.postal_code }} </span>
                          <span
                            v-if="contact.city"
                            style="font-family:inherit"
                          >{{ contact.city }}</span>
                        </div>
                        <div v-else>
                          <pre
                            v-if="contact.city"
                            style="font-family:inherit"
                          >{{ contact.city }}<span v-if="contact.postal_code">,</span> {{ contact.postal_code }}</pre>
                        </div>
                        {{ contact.country }} <br>
                      </div>
                      <div v-if="!contact.same_as_billing_address">
                        {{ contact.billing_first_name }} {{ contact.billing_last_name }}
                        {{ !contact.billing_first_name && !contact.billing_last_name ? contact.full_name : "" }}
                        <br>
                        <pre style="font-family:inherit">{{ contact.billing_address }}</pre>
                        <div v-if="locale == &quot;fi&quot; || locale == &quot;de&quot;">
                          <span v-if="contact.billing_postal_code">{{ contact.billing_postal_code }} </span>
                          <span
                            v-if="contact.billing_city"
                            style="font-family:inherit"
                          >{{ contact.billing_city }}</span>
                        </div>
                        <div v-else>
                          <pre
                            v-if="contact.billing_city"
                            style="font-family:inherit"
                          >{{ contact.billing_city }}<span
                            v-if="contact.billing_postal_code">,</span> {{ contact.billing_postal_code }}</pre>
                        </div>
                        {{ contact.billing_country }} <br>
                      </div>
                      <span v-if="contact.vat">{{ contact.vat }} <br></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--  <h3 class="mt-10 mb-3 w-full px-8">
              {{ $translate('deliverables', locale) }}
            </h3> -->
            <div class="mt-10 px-2 heading">
              <div class="flex px-4 w-full">
                <div class="w-6/12 px-2 print-heading-left">
                  {{ $translate('item', locale) }}
                </div>
                <div class="hidden md:flex w-6/12 px-2 justify-end print-heading-right">
                  <div class="w-1/6 pr-2 text-center">
                    {{ $translate('quantity', locale) }}
                  </div>
                  <div
                    v-if="isTaxEnable"
                    class="w-1/6 px-2 text-center"
                  >
                    {{ $translate('tax', locale) }}
                  </div>
                  <div class="w-2/6 px-2 text-right">
                    {{ $translate('price', locale) }}
                  </div>
                  <div class="w-2/6 text-right">
                    {{ $translate('total', locale) }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Required list items -->
            <div
              v-for="item in listofItems"
              :key="item.id"
              class="item w-full flex px-8 py-3 flex-wrap"
            >
              <div class="md:border-b border-grey-lighter w-full md:w-1/2 print-50 print-item-name">
                <div class="font-bold pb-6 text-base break-words">
                  {{ item.name }}
                </div>
                <div
                  v-if="item.description"
                  class="-mt-2 pb-6 text-grey-darker"
                >
                  <pre>{{ item.description }}</pre>
                </div>
              </div>

              <div
                class="border-bottom border-b border-grey-lighter pl-2 w-1/2 print-item-detail hidden md:flex justify-end print-flex">
                <div class="w-1/6 text-center px-2">
                  {{ item.quantity | showHideDecimal(currency.decimal_separator) }}
                </div>
                <div
                  v-if="isTaxEnable"
                  class="w-1/6 text-center px-2"
                >
                  {{ item.tax }}%
                </div>
                <div class="w-2/6 px-2 text-right">
                  <vue-numeric
                    :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                    :precision="2"
                    read-only
                    :currency="currency.sign"
                    :minus="false"
                    :decimal-separator="currency.decimal_separator"
                    :thousand-separator="currency.thousand_separator"
                    :min="0"
                    :value="item.price*(item.due_percentage/100)"
                  />
                </div>
                <div class="w-2/6">
                  <div class="text-right break-words">
                    <vue-numeric
                      :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                      :precision="2"
                      read-only
                      read-only-class="font-bold"
                      :currency="currency.sign"
                      :minus="false"
                      :decimal-separator="currency.decimal_separator"
                      :thousand-separator="currency.thousand_separator"
                      :min="0"
                      :value="calculateUnitPrice(item)"
                    />
                  </div>
                </div>
              </div>
              <div class="flex md:hidden justify-between w-full pb-5 leading-loosen border-bottom print-hidden">
                <div>
                  <strong>{{ $translate('quantity', locale) }}</strong> <br>
                  {{ item.quantity | showHideDecimal(currency.decimal_separator) }}
                </div>
                <div v-if="isTaxEnable">
                  <strong>{{ $translate('tax', locale) }}</strong><br> {{ item.tax }}%
                </div>
                <div>
                  <strong>{{ $translate('price', locale) }}</strong><br>
                  <vue-numeric
                    :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                    :precision="2"
                    read-only
                    :currency="currency.sign"
                    :minus="false"
                    :decimal-separator="currency.decimal_separator"
                    :thousand-separator="currency.thousand_separator"
                    :min="0"
                    :value="item.price"
                  />
                </div>
                <div>
                  <strong>{{ $translate('total', locale) }}</strong><br>
                  <vue-numeric
                    :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                    :precision="2"
                    read-only
                    :currency="currency.sign"
                    :minus="false"
                    :decimal-separator="currency.decimal_separator"
                    :thousand-separator="currency.thousand_separator"
                    :min="0"
                    :value="calculateUnitPrice(item)"
                  />
                </div>
              </div>
            </div>

            <!-- Sub total -->
            <div class="flex px-8 mt-3 justify-end print-footer">
              <div class="w-full md:w-2/5 flex flex-wrap print-footer-right">
                <div
                  v-show="discountTotal > 0"
                  class="w-1/2"
                >
                  {{ discount }}% {{ $translate('discount', locale) }}
                </div>
                <div
                  v-show="discountTotal > 0"
                  class="w-1/2 text-right"
                >
                  -
                  <vue-numeric
                    :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                    :precision="2"
                    read-only
                    :currency="currency.sign"
                    :decimal-separator="currency.decimal_separator"
                    :thousand-separator="currency.thousand_separator"
                    :min="0"
                    :value="viewDiscountedTotal"
                  />
                </div>
                <hr
                  v-show="discountTotal > 0"
                  class="my-5"
                >

                <div
                  v-if="duePercentage != 100"
                  class="w-1/2  "
                >
                  <div>
                    {{ $translate('due', locale) }}
                  </div>
                </div>
                <div
                  v-if="duePercentage != 100"
                  class="w-1/2   text-right"
                >
                  {{ duePercentage }}%
                </div>

                <div
                  v-if="isTaxEnable"
                  class="w-full"
                >
                  <hr
                    v-if="duePercentage != 100"
                    class="my-5"
                  >
                  <div
                    class="w-full flex"
                  >
                    <div class="w-1/2">
                      {{ $translate('subtotal', locale) }} <span
                      v-if="isTaxEnable && isTaxPriceIncluded">({{ $translate('exclTax', locale) }})</span>
                    </div>
                    <div class="w-1/2 text-right">
                      <vue-numeric
                        :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                        :precision="2"
                        read-only
                        :currency="currency.sign"
                        :minus="false"
                        :decimal-separator="currency.decimal_separator"
                        :thousand-separator="currency.thousand_separator"
                        :min="0"
                        :value="subTotal"
                      />
                    </div>
                  </div>
                  <div
                    v-for="(tax, key) in taxClasses"
                    :key="tax.id"
                    class="w-full flex flex-wrap mt-5"
                  >
                    <hr
                      v-if="isTaxEnable && key"
                      class="mb-5"
                    >
                    <div
                      v-if="isTaxEnable"
                      class="w-1/2"
                    >
                      {{ $translate('tax', locale) }} {{ key }}%
                    </div>
                    <div
                      v-if="isTaxEnable"
                      class="w-1/2 text-right"
                    >
                      <vue-numeric
                        :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                        :precision="2"
                        read-only
                        :currency="currency.sign"
                        :minus="false"
                        :decimal-separator="currency.decimal_separator"
                        :thousand-separator="currency.thousand_separator"
                        :min="0"
                        :value="tax*(duePercentage/100)"
                      />
                    </div>
                  </div>
                </div>

                <hr
                  class="my-5 thick"
                >
                <div class="w-1/2">
                  {{ $translate('total', locale) }} <span
                  v-if="isTaxEnable && isTaxPriceIncluded">({{ $translate('inclTax', locale) }})</span>
                </div>
                <div class="w-1/2 text-right">
                  <vue-numeric
                    :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                    :precision="2"
                    read-only
                    :currency="currency.sign"
                    :minus="false"
                    :decimal-separator="currency.decimal_separator"
                    :thousand-separator="currency.thousand_separator"
                    :min="0"
                    :value="total"
                  />
                </div>

                <div
                  v-for="(payment, key) in payments"
                  :key="key"
                  class="w-full"
                >
                  <hr
                    v-if="payments.length > 0"
                    class="my-5"
                  >

                  <div
                    class="flex items-center justify-between"
                  >
                    <div class="mr-3">
                      {{ $translate('paymentReceived', locale) }} <br>
                      <span class="text-grey-semi-light text-sm"> {{
                          $translate('date', locale)
                        }}: {{ payment.date | viewDate(locale) }} </span>
                    </div>
                    <div class="flex items-center">
                      <div>
                        -
                        <vue-numeric
                          v-model="payment.amount"
                          :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                          :precision="2"
                          read-only
                          class="ml-2 small-right"
                          :currency="currency.sign"
                          :minus="false"
                          :decimal-separator="currency.decimal_separator"
                          :thousand-separator="currency.thousand_separator"
                          :min="0"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <hr
                  class="my-5 thick"
                >

                <div class="w-1/2 text-lg font-bold">
                  {{ $translate('balanceDue', locale) }} ({{ currency.code }})
                </div>
                <div class="w-1/2 text-lg font-bold text-right">
                  <vue-numeric
                    :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                    :precision="2"
                    read-only
                    :currency="currency.sign"
                    :minus="false"
                    :decimal-separator="currency.decimal_separator"
                    :thousand-separator="currency.thousand_separator"
                    :min="0"
                    :value="remainingDueTotal"
                  />
                </div>
              </div>
            </div>

            <div v-show="notes">
              <hr class="mt-8">
              <div class="px-8 pt-5 pb-2 text-sm">
                <h3 class="mb-5 w-full">
                  {{ $translate('additionalNotes', locale) }}
                </h3>
                <pre style="max-width: 100%; word-break: break-word;">{{ notes }}</pre>
              </div>
            </div>

            <div
              v-show="remainingDueTotal > 0 && (validUntil != null)"
              class="bg-blue-washed-light rounded mt-10 mx-8"
            >
              <div class="px-5 pt-5 pb-2 md:px-8 md:pt-5 pb-2 ">
                <h3 class="mb-5 w-full">
                  {{ $translate('payment', locale) }}
                </h3>

                <div class="mb-5">
                  <div v-if="validUntil != null && remainingDueTotal > 0">
                    <div v-if="dueInDays > 0">
                      {{ $translate('pleasePay', locale) }}
                      <vue-numeric
                        :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                        :precision="2"
                        read-only
                        :currency="currency.sign"
                        :minus="false"
                        read-only-class="inline-block"
                        :decimal-separator="currency.decimal_separator"
                        :thousand-separator="currency.thousand_separator"
                        :min="0"
                        :value="remainingDueTotal"
                      />
                      {{ $translate('onOrBefore', locale) }} {{ validUntil | viewDate(locale) }} <span
                      v-if="locale != 'fi'">[{{ $translate('within', locale) }} {{ dueInDays }} <span
                      v-show="dueInDays > 1">{{ $translate('days', locale) }}</span><span
                      v-show="dueInDays == 1">{{ $translate('day', locale) }}</span>]</span><span v-else>[{{
                        dueInDays
                      }} {{ $translate('days', locale) }} {{ $translate('within', locale) }}]</span>.
                    </div>
                    <div v-if="dueInDays == 0">
                      {{ $translate('dueToday', locale) }}
                    </div>
                    <div v-if="dueInDays < 0">
                      {{ $translate('overDue', locale) }}
                    </div>
                    <div
                      v-if="!loading && !error"
                      class="mt-5"
                    >
                      <div
                        v-if="invoiceFooter != ''"
                        class="text-base leading-normal"
                      >
                        <pre>{{ invoiceFooter }} </pre>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-show="isChargeEnabled && remainingDueTotal > 0 && stripeAccountId != '' && (stripeAcceptCreditcard || stripeAcceptIdeal || stripeAcceptBancontact || stripeAcceptGiropay)">
                <hr class="white ">
                <div class="px-5 py-5 md:px-8 md:py-5 ">
                  <div class="paymentMethod mb-5">
                    <h3 class="mb-5 w-full">
                      {{ $translate('payOnline', locale) }}
                    </h3>
                  </div>

                  <div v-show="isPaymentLoading">
                    <img
                      src="@/assets/img/icons/loader.svg"
                      width="35"
                    >
                  </div>

                  <div
                    v-show="!isPaymentLoading && stripeAcceptCreditcard"
                    class="flex flex-wrap"
                  >
                    <div class="radio-container">
                      <input
                        id="cc"
                        v-model="paymentMethod"
                        type="radio"
                        value="creditcard"
                        name="paymentMethod"
                      >
                      <div class="border bg-white">
                        <label
                          for="cc"
                          class="radio-label cursor-pointer single-line"
                        >
                          <div class="flex flex-wrap w-full items-center">
                            <div class="mb-3 md:mb-0 w-full flex md:w-auto">
                              <div>
                                <img
                                  src="@/assets/img/icons/ico-visa.svg"
                                  width="37"
                                  class="align-middle"
                                >
                              </div>
                              <div class="mx-6">
                                <img
                                  src="@/assets/img/icons/ico-mastercard.svg"
                                  width="30"
                                  class="align-middle"
                                >
                              </div>
                              <div class="mr-6">
                                <img
                                  src="@/assets/img/icons/ico-ae.svg"
                                  width="38"
                                  class="align-middle"
                                >
                              </div>
                            </div>
                            <div class="text-grey-darker">
                              {{ $translate('payWithCreditCard', locale) }}
                            </div>
                          </div>
                        </label>
                        <div
                          v-show="paymentMethod == 'creditcard'"
                          class="px-3 my-4"
                        >
                          <form
                            id="creditcard-form"
                            @submit.prevent="creditcardSubmit"
                          >
                            <div class="flex flex-wrap w-full">
                              <div class="w-full md:w-4/12">
                                <div id="card-element"/>
                              </div>
                              <div class="mt-3 md:mt-0 md:ml-4">
                                <button class="button dark">
                                  <span class="px-5">{{ $translate('pay', locale) }}</span>
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div class="check"/>
                      </div>
                    </div>
                  </div>

                  <div
                    v-show="!isPaymentLoading && currency.code == 'EUR' && stripeAcceptIdeal"
                    class="flex flex-wrap mt-4"
                  >
                    <div class="radio-container">
                      <input
                        id="ideal"
                        v-model="paymentMethod"
                        type="radio"
                        value="ideal"
                        name="paymentMethod"
                      >
                      <div class="border bg-white ">
                        <label
                          for="ideal"
                          class="radio-label cursor-pointer single-line"
                        >
                          <div class="flex w-full items-center">
                            <div class="mr-8">
                              <img
                                src="@/assets/img/icons/ico-ideal.svg"
                                width="30"
                                class="align-middle"
                              >
                            </div>
                            <div class="text-grey-darker">
                              {{ $translate('payWithIdeal', locale) }}
                            </div>
                          </div>
                        </label>
                        <div
                          v-show="paymentMethod == 'ideal'"
                          class="px-3 my-4"
                        >
                          <form
                            id="ideal-form"
                            @submit.prevent="idealSubmit"
                          >
                            <div
                              v-show="!isPaymentLoading"
                              class="flex flex-wrap w-full"
                            >
                              <div class="w-full md:w-4/12">
                                <div id="ideal-bank-element"/>
                              </div>
                              <div class="mt-3 md:mt-0 md:ml-4">
                                <button class="button dark">
                                  <span class="px-5">{{ $translate('pay', locale) }}</span>
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div class="check"/>
                      </div>
                    </div>
                  </div>

                  <div
                    v-show="!isPaymentLoading && currency.code == 'EUR' && stripeAcceptBancontact"
                    class="flex flex-wrap mt-4"
                  >
                    <div class="radio-container">
                      <input
                        id="bancontact"
                        v-model="paymentMethod"
                        type="radio"
                        value="bancontact"
                        name="paymentMethod"
                      >
                      <div class="border bg-white ">
                        <label
                          for="bancontact"
                          class="radio-label cursor-pointer single-line"
                        >
                          <div class="flex w-full items-center">
                            <div class="mr-6">
                              <img
                                src="@/assets/img/icons/ico-bancontact.svg"
                                width="40"
                                class="align-middle"
                              >
                            </div>
                            <div class="text-grey-darker">
                              {{ $translate('payWithBancontact', locale) }}
                            </div>
                          </div>
                        </label>
                        <div
                          v-show="paymentMethod == 'bancontact'"
                          class="px-3 my-4"
                        >
                          <form
                            id="bancontact-form"
                            @submit.prevent="bancontactSubmit"
                          >
                            <div
                              v-show="!isPaymentLoading"
                              class="flex flex-wrap w-full"
                            >
                              <div class="mt-3 md:mt-0 w-full md:w-8/12">
                                <button class="button dark">
                                  <span class="px-5">{{ $translate('pay', locale) }}</span>
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div class="check"/>
                      </div>
                    </div>
                  </div>

                  <div
                    v-show="!isPaymentLoading && currency.code == 'EUR' && stripeAcceptGiropay"
                    class="flex flex-wrap mt-4"
                  >
                    <div class="radio-container">
                      <input
                        id="giropay"
                        v-model="paymentMethod"
                        type="radio"
                        value="giropay"
                        name="paymentMethod"
                      >
                      <div class="border bg-white ">
                        <label
                          for="giropay"
                          class="radio-label cursor-pointer single-line"
                        >
                          <div class="flex w-full items-center">
                            <div class="mr-6">
                              <img
                                src="@/assets/img/icons/ico-giropay.svg"
                                width="40"
                                class="align-middle"
                              >
                            </div>
                            <div class="text-grey-darker">
                              {{ $translate('payWithGiropay', locale) }}
                            </div>
                          </div>
                        </label>
                        <div
                          v-show="paymentMethod == 'giropay'"
                          class="px-3 my-4"
                        >
                          <form
                            id="giropay-form"
                            @submit.prevent="giropaySubmit"
                          >
                            <div
                              v-show="!isPaymentLoading"
                              class="flex flex-wrap w-full"
                            >
                              <div class="mt-3 md:mt-0 w-full md:w-8/12">
                                <button class="button dark">
                                  <span class="px-5">{{ $translate('pay', locale) }}</span>
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div class="check"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <!-- Mollie start -->

              <div v-show="mollieConnectEnabled && remainingDueTotal > 0">
                <hr class="white ">
                <div class="px-5 py-5 md:px-8 md:py-5">
                  <div class="mb-5">
                    <h3 class="mb-5 w-full">
                      {{ $translate('payOnline', locale) }}
<!--                      (<span v-for="(method, index) in molliePaymentMethods" :key="index"><span v-if="index > 0">, </span>{{ method.id }}</span>)-->
                    </h3>
                  </div>

                  <div class="flex flex-col items-start">
                    <button
                      v-if="!mollieLoading"
                      class="button mb-4"
                      @click="payMollie"
                    >
                      <span class="px-5">{{ $translate('pay', locale) }}</span>
                    </button>

                    <div class="flex my-2" v-if="molliePaymentMethods.length">
                      <img
                        v-for="method in molliePaymentMethods"
                        :key="method.id"
                        :src="method.image.svg"
                        :alt="method.id"
                        class="mr-2"
                        style="height: 28px;"
                      >
                    </div>

                  </div>
                </div>
              </div>
              <!-- Mollie end -->


              <!-- <div
                v-if="remainingDueTotal <= 0"
                class="mb-5 px-5 pb-5 md:px-8 md:pb-8 "
              >
                This invoice has been paid.
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!loading && viewType == 'token' && !error && !paymentSucceed"
      class="header simple w-full text-center py-5 z-10 relative bg-white"
    >
      Powered by <a href="https://octoa.com">Octoa</a>
    </div>
  </div>
</template>
<script>

import config from '@/config';
import {logException} from '@/helpers';
import {format} from 'date-fns';
import {calculateEachTaxClassPrice, calculateTaxTotal, trim2Decimals} from '@/utils/helpers';
import {loadStripe} from '@stripe/stripe-js';

export default {
  name: 'InvoicePreview',
  data() {
    return {
      loading: true,
      isPdfLoading: false,
      pdfLoaderProgress: 0,
      metaTitle: 'Loading',
      currency: {
        sign: '€'
      },
      logo: {path: '', width: 0, height: 0},
      locale: 'en',
      viewType: 'token',
      currentView: 1,
      project: null,
      invoice: {},
      contact: {},
      moneybirdSettings: null,
      numberPrefix: null,
      issuedAt: null,
      validUntil: null,
      invoiceNumber: '',
      isTaxEnable: false,
      isTaxPriceIncluded: false,
      id: this.$route.params.invoiceId,
      token: this.$route.params.token,
      taxPercent: 21,
      discount: 0,
      discountPercent: 0,
      listofItems: [],
      listofAdditonalItems: [],
      listofPaymentItems: [],
      notes: '',
      signature: '',
      paymentInfo: '',
      user: {},
      company: {},
      invoiceFooter: '',
      error: false,
      payments: [],
      dueInDays: 0,
      duePercentage: 100,
      dueTotal: 0,
      invoiceNumberToolTipContent: {
        content: `
          The moment you send out the invoice or mark it as sent, an invoice number will be automatically generated based on your last invoice number.
        `
      },
      addPayment: {
        date: null,
        amount: 0,
      },
      isMobile: false,
      isPaymentLoading: false,
      stripeScriptElement: null,
      stripe: null,
      stripeAccountId: '',
      stripeListofPaymentMethods: [],
      stripeAcceptCreditcard: false,
      stripeAcceptIdeal: false,
      stripeAcceptBancontact: false,
      stripeAcceptGiropay: false,
      publishableKey: '',
      clientSecret: '',
      idealBank: null,
      creditcardElement: null,
      paymentMethod: '',
      paymentSucceed: false,
      isChargeEnabled: true,
      subject: null,
      mollieConnectEnabled: false,
      mollieLoading: false,
      molliePaymentMethods: [],
    };
  },
  computed: {
    taxClasses() {
      let results = calculateEachTaxClassPrice(this.listofItems, this.originalSubTotal, this.discountedTotal, this.isTaxPriceIncluded);
      return results;
    },
    originalSubTotal() {
      return this.listofItems.reduce(function (total, item) {
        return (Number(total) + Number((item.price * (item.due_percentage / 100)) * item.quantity)).toFixed(2);
      }, 0);
    },
    subTotal() {
      let originalSubTotal = this.listofItems.reduce((total, item) => {
        let itemTotal = item.price * (item.due_percentage / 100) * item.quantity;
        return total + itemTotal;
      }, 0);

      let subTotal = (originalSubTotal - this.discountTotal) * (this.duePercentage / 100);

      if (this.isTaxEnable && this.isTaxPriceIncluded) {
        let tax = this.taxTotal * (this.duePercentage / 100);
        let value = subTotal - tax;
        return trim2Decimals(value);
      }
      return trim2Decimals(subTotal);
    },
    taxTotal() {
      let results = calculateTaxTotal(this.listofItems, this.originalSubTotal, this.discountedTotal, this.isTaxPriceIncluded);
      return results;
    },
    discountTotal() {
      let total = 0;
      total = this.originalSubTotal * (this.discount / 100);
      return total;
    },
    discountedTotal() {
      let total = 0;
      if (this.discountTotal > 0) {
        total = this.originalSubTotal - this.discountTotal;
      }

      return total;
    },
    viewDiscountedTotal() {
      if (!this.discountTotal) {
        return 0;
      }

      let taxPrice = this.isTaxPriceIncluded && this.isTaxEnable ? this.taxTotal : 0;
      let discountTotal = (parseFloat(this.subTotal) + taxPrice + parseFloat(this.discountTotal)) * (parseFloat(this.discount) / 100);

      return discountTotal;
    },
    total() {
      // Ensure all values are numbers
      const originalSubTotal = parseFloat(this.originalSubTotal) || 0;
      let discountTotal = this.viewDiscountedTotal || 0;

      const taxTotal = parseFloat(this.taxTotal) || 0;

      // Adjust discountTotal based on whether tax is included
      if (this.isTaxEnable && this.isTaxPriceIncluded) {
        discountTotal = parseFloat(this.discountTotal) || 0;
      }

      let total = 0;
      if (Math.sign(originalSubTotal) === -1) {
        total = originalSubTotal + discountTotal;
      } else {
        total = originalSubTotal - discountTotal;
      }

      // Add taxTotal if tax is not included in the price
      if (this.isTaxEnable && !this.isTaxPriceIncluded) {
        total += taxTotal;
      }

      return total;
    },
    remainingDueTotal() {
      let paymentTotal = 0;
      this.payments.forEach(payment => {
        paymentTotal += Number(payment.amount);
      });

      let remaining = Number(this.total) - Number(paymentTotal);

      if (this.duePercentage < 100) {
        remaining = Number(this.dueTotal) - Number(paymentTotal);
      }

      return remaining;
    },
    stripeAmount() {
      let paymentTotal = 0;
      this.payments.forEach(payment => {
        paymentTotal += Number(payment.amount);
      });

      let remaining = Number(this.total) - Number(paymentTotal);
      if (this.duePercentage < 100) {
        remaining = Number(this.dueTotal) - Number(paymentTotal);
      }

      let rawAmount = remaining.toLocaleString('en', {useGrouping: false, minimumFractionDigits: 2});
      let roundedAmount = (Math.round(rawAmount * 100) / 100).toFixed(2);
      let amount = roundedAmount.replace('.', '');
      return amount;
    }
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, {passive: true});
    }
  },
  async mounted() {
    await this.initData();
    // if failed or succeeded in route query
    if (this.$route.query.redirect_status) {
      const publishableKey = process.env.VUE_APP_STRIPE_PUBLISH_KEY || 'pk_test_51HRuJUJKtTsQdEmujtFwpbPWizfaW59pVmCJfBcS2TSUbDSRFdgvzndjUwsn7ohNRviEHXvoJM9aimDKLG7hJXCb00c2cLRU7O';

      try {
        this.stripe = await loadStripe(publishableKey, {
          stripeAccount: this.stripeAccountId,
          locale: this.locale
        });
      } catch (e) {
        console.error('Error loading Stripe:', e);
      }

      const clientSecret = this.$route.query.payment_intent_client_secret;
      // console.log('Client secret:', clientSecret)
      if (clientSecret) {
        // console.log('Attempting to confirm payment', clientSecret)

        // get payment intent
        const paymentIntent = await this.stripe.retrievePaymentIntent(clientSecret);

        if (paymentIntent.paymentIntent.status === 'succeeded') {
          this.paymentSucceed = true;
        }

        if (paymentIntent.paymentIntent.status === 'requires_action') {
          this.paymentSucceed = 'failed';
        }

        if (paymentIntent.paymentIntent.status === 'requires_action' || paymentIntent.paymentIntent.status === 'requires_confirmation') {
          try {
            const result = await this.stripe.handleCardAction(clientSecret);
            // console.log('confirmPayment result:', result);
            if (result.error) {
              // console.error('Payment confirmation failed:', result.error.message)
              this.paymentSucceed = 'failed';
            } else {
              if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
                this.paymentSucceed = true;
              } else {
                this.paymentSucceed = 'failed';
              }
            }
          } catch (error) {
            console.error('Error during payment confirmation:', error);
            this.paymentSucceed = 'failed';
          }
        }
      }
    }

    this.loading = false;

    this.onResize();
    window.addEventListener('resize', this.onResize, {passive: true});

    if (this.$route.query.redirect_status) {
      return;
    }

    if (this.remainingDueTotal > 0) {
      try {
        await this.initStripe();
      } catch (e) {
        console.error('Error loading Stripe:', e);
      }
    }
  },
  methods: {
    reload() {
      window.location = window.location.pathname;
    },
    async payMollie() {
      try {
        this.mollieLoading = true;

        const mollieResult = await this.$api.get('mollie').pay(this.invoiceId);

        if (mollieResult) {
          window.location = mollieResult.data._links.checkout.href;
        }
      } catch (e) {
        this.mollieLoading = false;
        this.$toasted.global.general_error({
          message: 'Mollie connection error.'
        });
      }
    },
    async initStripe() {
      const publishableKey = process.env.VUE_APP_STRIPE_PUBLISH_KEY || 'pk_test_51HRuJUJKtTsQdEmujtFwpbPWizfaW59pVmCJfBcS2TSUbDSRFdgvzndjUwsn7ohNRviEHXvoJM9aimDKLG7hJXCb00c2cLRU7O';

      try {
        this.stripe = await loadStripe(publishableKey, {
          stripeAccount: this.stripeAccountId,
          locale: this.locale
        });
      } catch (e) {
        console.error('Error loading Stripe:', e);
      }

      var elements = this.stripe.elements();
      var creditCardStyle = {
        base: {
          color: '#001419',
          fontSmoothing: 'antialiased',
          fontSize: '14px',
          '::placeholder': {
            color: '#aab7c4'
          },
        }
      };
      var iDealStyle = {
        base: {
          padding: '10px 12px',
          color: '#001419',
          fontSmoothing: 'antialiased',
          fontSize: '14px',
          '::placeholder': {
            color: '#aab7c4'
          },
        }
      };
      var bancontactStyle = {
        base: {
          padding: '10px 12px',
          color: '#001419',
          fontSmoothing: 'antialiased',
          fontSize: '14px',
          '::placeholder': {
            color: '#aab7c4'
          },
        }
      };
      var giropayStyle = {
        base: {
          padding: '10px 12px',
          color: '#001419',
          fontSmoothing: 'antialiased',
          fontSize: '14px',
          '::placeholder': {
            color: '#aab7c4'
          },
        }
      };

      this.creditcardElement = elements.create('card', {style: creditCardStyle});
      this.creditcardElement.mount('#card-element');

      if (this.currency.code == 'EUR' && this.stripeAcceptIdeal) {
        this.idealBank = elements.create('idealBank', {style: iDealStyle});
        this.idealBank.mount('#ideal-bank-element');
      }

      let invoiceReference = `#${this.invoiceNumber}`;
      if (this.numberPrefix) {
        invoiceReference = `#${this.numberPrefix}${this.invoiceNumber}`;
      }

      const postData = {
        amount: this.stripeAmount,
        currency: this.currency.code,
        stripeAccountId: this.stripeAccountId,
        invoiceReference: invoiceReference,
        statementDescriptor: `Invoice ${invoiceReference}`,
        invoiceId: this.invoiceId,
        paymentMethods: this.stripeListofPaymentMethods
      };

      try {
        const {data} = await this.$api.get('stripe').create(this.company.id, postData);
        this.clientSecret = data.clientSecret;
        this.isChargeEnabled = data.isChargeEnabled;
      } catch (e) {
        this.$toasted.global.general_error({
          message: e.response.data.message
        });
        this.stripeAcceptIdeal = false;
        this.stripeAcceptBancontact = false;
        this.stripeAcceptGiropay = false;
      }
    },
    async creditcardSubmit() {

      this.isPaymentLoading = true;

      let that = this;
      this.stripe.confirmCardPayment(this.clientSecret, {
        payment_method: {
          card: this.creditcardElement,
          billing_details: {
            name: this.contact.full_name,
          }
        }
      }).then(function (result) {
        if (result.error) {
          that.isPaymentLoading = false;
          that.$toasted.global.general_error({
            message: result.error.message
          });
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === 'succeeded') {
            that.paymentSucceed = true;
          }
        }
      });
    },
    async idealSubmit() {

      const {error} = await this.stripe.confirmIdealPayment(
        this.clientSecret,
        {
          payment_method: {
            ideal: this.idealBank,
            billing_details: {
              name: this.contact.full_name,
            }
          },
          return_url: window.location.href,
        }
      );
    },
    async bancontactSubmit() {

      const {error} = await this.stripe.confirmBancontactPayment(
        this.clientSecret,
        {
          payment_method: {
            billing_details: {
              name: this.contact.full_name,
            }
          },
          return_url: window.location.href,
        }
      );
    },

    async giropaySubmit() {

      const {error} = await this.stripe.confirmGiropayPayment(
        this.clientSecret,
        {
          payment_method: {
            billing_details: {
              name: this.contact.full_name,
            }
          },
          return_url: window.location.href,
        }
      );
    },

    onResize() {
      this.isMobile = window.innerWidth < 769;
    },
    async initData() {
      let target = this.token;
      let that = this;

      try {
        let result = {};
        if (!this.token) {
          target = this.id;
          this.viewType = 'edit';
          result = await this.$api.get('invoice').detail(target, `${this.viewType}`);
        } else {
          this.viewType = 'token';
          result = await this.$api.get('invoice').view(target, `${this.viewType}`);
        }

        let invoice = result.data.invoice;
        this.timezone = result.data.companySettings.timezone;
        this.numberPrefix = result.data.companySettings.numberPrefix;
        this.invoiceFooter = result.data.companySettings.invoiceFooter;
        this.stripeAccountId = result.data.companySettings.stripeAccountId;
        this.paymentInfo = result.data.companySettings.paymentInfo;
        this.mollieConnectEnabled = result.data.companySettings.mollieConnectEnabled;

        this.logo.path = result.data.logo;
        if (this.logo.path) {
          let image = new Image();
          image.src = this.logo.path;
          image.onload = function () {
            that.logo.width = this.width;
            that.logo.height = this.height;
          };
        }

        this.moneybirdSettings = result.data.companySettings.moneybirdSettings;
        this.currency = invoice.currency;

        this.invoice = invoice;
        this.invoiceId = invoice.id;
        this.contact = invoice.project ? invoice.project.contact : invoice.contact;
        this.name = invoice.name;
        this.subject = invoice.subject;
        if (invoice.invoice_number) {
          this.invoiceNumber = invoice.invoice_number;
        }
        this.issuedAt = invoice.issued_at;
        this.validUntil = invoice.valid_until;
        this.isTaxEnable = invoice.is_tax_enable;
        this.isTaxPriceIncluded = invoice.is_tax_price_included;
        this.taxPercent = invoice.tax;
        this.discount = invoice.discount;
        this.notes = invoice.notes;
        this.dueTotal = invoice.due_total;
        this.duePercentage = invoice.due_percentage;
        this.dueInDays = result.data.dueInDays;

        this.user = invoice.company.users[0];
        this.company = invoice.company;
        this.locale = invoice.locale;

        let metaTitle = '';
        if (this.subject == '' || this.subject == null) {
          metaTitle = `: ${this.name}`;
        } else if (this.name == '' || this.name == null) {
          metaTitle = `: ${this.subject}`;
        }

        if (!this.token) {
          this.metaTitle = `Preview Invoice${metaTitle}`;
        } else {
          this.metaTitle = `Invoice${metaTitle}`;

          // if (!this.$route.query.checkstatus) {
          //   if (invoice.project && invoice.project.id != this.$route.params.projectId) {
          //     window.location = 'https://octoa.com'
          //   }
          // }
        }

        this.listofItems = [];
        invoice.items.forEach(function (item) {
          item.isTaxEnable = item.is_taxable;
          if (item.is_optional) {
            that.listofAdditonalItems.push(item);
          } else {
            that.listofItems.push(item);
          }
        });

        if (this.$route.query.checkstatus && invoice.state === 'paid') {
          this.paymentSucceed = true;
        }

        if (this.$route.query.checkstatus && invoice.state !== 'paid') {
          this.mollieLoading = true;

          setTimeout(() => {
            this.initData();
          }, 5000);
        }

        this.payments = [];
        invoice.invoice_payments.forEach(payment => {
          this.payments.push({
            id: payment.id,
            invoice_id: payment.invoice_id,
            date: payment.date,
            amount: payment.amount,
            reference: 'paid'
          });
        });

        this.stripeListofPaymentMethods = result.data.companySettings.stripePaymentMethods;
        for (const method of this.stripeListofPaymentMethods) {
          if (method == 'card') {
            this.stripeAcceptCreditcard = true;
          }
          if (method == 'ideal') {
            this.stripeAcceptIdeal = true;
          }
          if (method == 'bancontact') {
            this.stripeAcceptBancontact = true;
          }
          if (method == 'giropay') {
            this.stripeAcceptGiropay = true;
          }
        }

        if (this.mollieConnectEnabled) {
          let methodsResult = await this.$api.get('mollie').getMethods(this.invoiceId);
          this.molliePaymentMethods = methodsResult.data._embedded.methods;
        }

      } catch (e) {
        this.loading = false;
        this.metaTitle = 'Invoice not found';
        this.error = true;
        this.$router.push('/');
      }
    },
    showAddPayment() {
      this.addPayment.date = new Date();
      this.addPayment.amount = this.remainingDueTotal;
      this.$modal.show('paymentModal');
    },
    async recordPayment() {
      if (this.addPayment.date == null || this.addPayment.amount <= 0) {
        this.$toasted.global.general_error({
          message: 'Please enter a valid date or amount.'
        });
        return;
      }

      let postData = {
        id: this.invoice.id,
        date: format(this.addPayment.date, 'YYYY-MM-DD'),
        amount: this.addPayment.amount,
        reference: 'paid'
      };

      const {data} = await this.$api.get('invoice').addPayment(this.invoice.id, postData);
      this.addPayment.id = null;
      this.addPayment.date = null;
      this.addPayment.amount = 0;

      this.$modal.hide('paymentModal');
      await this.initData();
    },
    calculateUnitPrice(item) {
      return (item.price * (item.due_percentage / 100)) * item.quantity;
    },
    dateFormatter(value) {
      return format(value, 'YYYY-MM-DD');
    },
    onPdfProgress($event) {
      this.pdfLoaderProgress = $event;
    },
    async savePdf() {

      var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      const data = {
        companyId: this.company.id,
        invoiceId: this.invoice.id,
        remainingDueTotal: this.remainingDueTotal,
        subTotal: this.subTotal,
        total: this.total,
        viewDiscountedTotal: this.viewDiscountedTotal,
        taxClasses: this.taxClasses,
        isMobile: this.isMobile,
        isSafari: isSafari,
        logoWidth: this.logo.width,
        logoHeight: this.logo.height,
      };

      this.isPdfLoading = true;
      await this.$api.get('pdf').invoice(data).then(response => {
        if (!isSafari && !this.isMobile) {
          let blob = new Blob([response.data], {type: 'application/pdf'});
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `invoice-${this.invoice.invoice_number}.pdf`;
          link.click();
        } else {
          window.location.href = response.data;
        }

        this.isPdfLoading = false;
      });
    },
    async copyShareLink() {
      if (this.invoice.state == 'draft') {
        const can = await this.$alert.confirm({
          title: 'Invoice has not been sent out',
          text: 'In order to copy a share link, we will mark this invoice as sent.'
        });

        if (can) {
          try {
            await this.$api.get('invoice').updateState(this.invoiceId, 'sent');
            let link;
            if (this.invoice.project) {
              link = `${config.frontBaseUrl}/view/${this.invoice.project.id}/invoice/${this.invoice.token}`;
            } else {
              link = `${config.frontBaseUrl}/view/invoice/${this.invoice.token}`;
            }
            let dummy = document.createElement('textarea');
            document.body.appendChild(dummy);
            dummy.value = link;
            dummy.select();
            document.execCommand('copy');
            document.body.removeChild(dummy);

            this.$toasted.global.general_success({
              message: 'Share link has been copied to your clipboard.'
            });

            setTimeout(() => {
              window.location.reload();
            }, 500);

          } catch (e) {
            this.$toasted.global.api_error(e);
            logException(e);
          }
        }
      } else {
        let link;
        if (this.invoice.project) {
          link = `${config.frontBaseUrl}/view/${this.invoice.project.id}/invoice/${this.invoice.token}`;
        } else {
          link = `${config.frontBaseUrl}/view/invoice/${this.invoice.token}`;
        }

        let dummy = document.createElement('textarea');
        document.body.appendChild(dummy);
        dummy.value = link;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);

        this.$toasted.global.general_success({
          message: 'Share link has been copied to your clipboard.'
        });
      }
    },
    async deleteItem() {
      const canDelete = await this.$alert.confirm({
        title: 'Are you sure you want to delete this invoice?',
        text: 'If you delete this, sent item will no longer accessible by the client.',
      });

      if (canDelete) {
        try {
          try {
            const postData = {
              invoiceId: this.invoiceId,
            };
            await this.$api.get('invoice').delete(postData);
            this.$router.push(`/projects/${this.invoice.project_id}`);

          } catch (e) {
            this.$toasted.global.api_error(e);
            logException(e);
          }

        } catch (e) {
          logException(e);
        }
      }
    },
    async markAsSent() {
      const can = await this.$alert.confirm({
        title: 'Are you sure you want to mark this invoice as sent?',
      });

      if (can) {
        try {
          await this.$api.get('invoice').updateState(this.invoiceId, 'sent');

          if (this.moneybirdSettings.auto_export_invoices) {
            try {
              await this.$api.get('invoice').exportMoneybird(this.user.company_id, {invoiceId: this.invoiceId});

              setTimeout(() => {
                window.location.reload();
              }, 500);
            } catch (e) {
              if (e.response.data.message.error == 'creating sales invoices is limited') {
                this.$toasted.global.general_error({
                  message: 'Oops, your Moneybird account has reached the allowed sale invoices limit.'
                });
                return false;
              }
              if (e.response.data.message.error.send_invoices_to_email[0] == 'includes a domain which cannot receive emails') {
                this.$toasted.global.general_error({
                  message: `Your client: ${e.response.data.email_address} email address is not valid.`
                });
                return false;
              }

              this.$toasted.global.general_error({
                message: 'Error exporting to Moneybird, try to reconnect your account through the setting again.'
              });
            }
          } else {
            setTimeout(() => {
              window.location.reload();
            }, 500);
          }
        } catch (e) {
          this.$toasted.global.api_error(e);
        }
      }
    },
    async markAsUnsent() {

      const can = await this.$alert.confirm({
        title: 'Are you sure you want to mark this invoice as draft?',
      });

      if (can) {
        try {
          await this.$api.get('invoice').updateState(this.invoiceId, 'draft');
          setTimeout(() => {
            window.location.reload();
          }, 500);

        } catch (e) {
          this.$toasted.global.api_error(e);
          logException(e);
        }
      }
    },
    async edit() {
      if (this.invoice.project) {
        this.$router.push(`/projects/${this.invoice.project.id}/invoice/${this.invoice.id}`);
      } else {
        this.$router.push(`/invoice/${this.invoice.id}`);
      }
    },
  }
};
</script>
